import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, message, Table, Modal, Input } from "antd";
import { Icon } from "@iconify/react";
import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import "./style.css";
import { useAppContext } from "../../plugin/AppContext";
import flights from "../../models/flights";
import Loader from "../../plugin/loader/loader";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import separation from "../../models/separation";

const { confirm } = Modal;

const center = {
  lat: 25.0732972,
  lng: 55.728425,
};

const params = {
  ...center,
  // lat: 25.248665,
  // lng: 55.352917,
  // lat: 24.973472000000072,
  // lng: 56.06838900000008,
  radius: 150,
  limit: 50,
  airport: "DXB",
};

const defaultAircraftSeparation = {
  sourceFlightNumber: "",
  safeSeparation: null,
  isSeparationChange: false,
};
const defaultMinimumSeparation = {
  minimumSeparation: 66,
  separationCorrection: 1,
};
const AircraftDataTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [rowDragging, setRowDragging] = useState(false);
  const [minimumSeparation, setMinimumSeparation] = useState(
    defaultMinimumSeparation
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aircraftSeparation, setAircraftSeparation] = useState(
    defaultAircraftSeparation
  );

  const { aircraftsData } = useAppContext();
  const RowContext = createContext({});

  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const resetSwapData = (flightNumber) => {
    const resetSwappedData = {
      sourceFlightNumber: flightNumber,
      targetFlightNumber: null,
      swapPosition: null,
      isSwapped: false,
    };
    setArrivingOrder(resetSwappedData);
  };
  const ResetSwapDataButton = ({ row }) => {
    return row.isSwapped ? (
      <Button
        type="text"
        size="small"
        icon={<CloseOutlined />}
        onClick={() => resetSwapData(row?.flightNumber)}
      />
    ) : null;
  };
  const Row = (props) => {
    const rowKey = props["data-row-key"];
    // if (!rowKey) {
    //   return null; // Skip rendering if data is not loaded
    // }

    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );
    useEffect(() => {
      setRowDragging(isDragging && rowKey);
    }, [isDragging]);
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = data.findIndex(
        (record) => record?.flightNumber === active?.id
      );
      const overIndex = data.findIndex(
        (record) => record.flightNumber === over?.id
      );
      if (!active?.id || !over?.id) return;
      const swappedData = {
        sourceFlightNumber: active.id,
        targetFlightNumber: over?.id,
        previousFlightNumber: data[activeIndex - 1]?.flightNumber || null, // if active index 0, previous flight number will be null
        nextFlightNumber: data[activeIndex + 1]?.flightNumber || null, // if active index is last index, next flight number will be null
        swapPosition: activeIndex > overIndex ? "BEFORE" : "AFTER",
        isSwapped: true,
      };
      confirm({
        title: `do you want to place ${active.id} ${swappedData?.swapPosition} ${over.id}`,
        onOk() {
          setArrivingOrder(swappedData);
        },
      });
    }
  };

  const column = [
    {
      key: "DELETESWAP",
      align: "center",
      width: 60,
      render: (_, row) => <ResetSwapDataButton row={row} />,
    },
    {
      key: "SORT",
      align: "center",
      width: 60,
      render: () => <DragHandle />,
    },
    {
      title: "SEQUENCE",
      dataIndex: "sequence",
      key: "sequence",
      fixed: "left",
      width: 90,
      sorter: (a, b) => a.sequence - b.sequence,
      defaultSortOrder: "ascend",
      // sortDirections: ["ascend"],
      // render: (_, __, index) => (page - 1) * 5 + index + 1,
    },
    {
      title: "ARRIVAL",
      children: [
        {
          title: (
            <>
              <div className="fw-bold">LORID</div>
              <div>DB531</div>
            </>
          ),
          dataIndex: ["arrivingFrom"],
          key: "LORID",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "LORID" ? row?.identification?.callsign : "",
        },
        {
          title: (
            <>
              <div className="fw-bold">VUTEB</div>
              <div>DB529</div>
            </>
          ),
          dataIndex: ["arrivingFrom"],
          key: "VUTEB",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "VUTEB" ? row?.identification?.callsign : "",
        },
        {
          title: (
            <>
              <div className="fw-bold">PUVAL</div>
              <div>KEBOG</div>
            </>
          ),
          dataIndex: ["arrivingFrom"],
          key: "PUVAL",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "ITLAB" ? row?.identification?.callsign : "",
        },
        {
          title: "IMPED",
          dataIndex: ["arrivingFrom"],
          key: "Imped",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "IMPED" ? row?.identification?.callsign : "",
        },
      ],
    },
    {
      title: "AIRCRAFT TPYE",
      dataIndex: "aircraft",
      key: "aircraft",
      render: (aircraft) => aircraft?.model?.code,
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "SEPARATION (NM)",
      children: [
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>MNM</span>
              <span>{minimumSeparation?.minimumSeparation / 22 + minimumSeparation?.separationCorrection}</span>
            </div>
          ),
          dataIndex: ["separation"],
          key: "separation",
          width: 60,
          render: (separation) => separation / 22,
        },
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>+</span>
              <span>{minimumSeparation?.separationCorrection}</span>
            </div>
          ),
          dataIndex: ["safeSeparation"],
          key: "safeSeparation",
          width: 60,
          render: (safeSeparation, row) => (
            <Button
              variant="text"
              color={row?.isSeparationChange ? "danger" : "default"}
              onClick={() =>
                showModal(
                  row?.flightNumber,
                  safeSeparation,
                  row?.isSeparationChange
                )
              }
            >
              {safeSeparation / 22}
            </Button>
          ),
        },
      ],
      // dataIndex: "separation",
      // key: "separation",
      // render: (separation) => separation / 24,
    },
    {
      title: "GAP AT 4 DME",
      dataIndex: "aircraftGap",
      key: "aircraftGap",
      render: (aircraftGap) =>
        (Math.floor(Number(aircraftGap) * 10) / 10).toFixed(1),
    },
    {
      title: "ESTIMATE ARR. TIME (UTC)",
      dataIndex: "estimateArrivalTime",
      key: "estimateArrivalTime",
      render: (estimateArrivalTime) => {
        let arrTime = moment(estimateArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
      sorter: (a, b) =>
        a.estimateArrivalTime?.localeCompare(b.estimateArrivalTime),
      // sortDirections: ["ascend"],
      // defaultSortOrder: "ascend",
    },
    {
      title: "DELAY",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => {
        const sign = delay < 0 ? "-" : "";
        const momentObject = moment.duration(Math.abs(delay));

        const hours = momentObject.hours();
        const minutes = momentObject.minutes();
        const seconds = momentObject.seconds();

        return `${sign}${hours}:${minutes}:${seconds}`;
      },
    },
    {
      title: "CALCULATED ARR. TIME (UTC)",
      dataIndex: "calculatedArrivalTime",
      key: "calculatedArrivalTime",
      render: (calculatedArrivalTime) => {
        let arrTime = moment(calculatedArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
    },
    {
      title: "REMAINING DISTANCE (NM)",
      dataIndex: "remainingDistance",
      key: "remainingDistance",
      render: (remainingDistance) =>
        (Math.floor(Number(remainingDistance) * 10) / 10).toFixed(1),
      sorter: (a, b) => a.remainingDistance - b.remainingDistance,
    },
    {
      title: "AIRCRAFT STATUS",
      dataIndex: "isOnRoute",
      key: "isOnRoute",
      render: (isOnRoute) => (isOnRoute ? "on route" : "off route"),
    },
    {
      title: "C.D.A. DIFF",
      dataIndex: "altitudeGap",
      key: "altitudeGap",
      render: (altitudeGap) => Math.floor(Number(altitudeGap)),
    },
    {
      title: "EXPECTED APPROACH TIME",
      dataIndex: "expectedApproachTime",
      key: "expectedApproachTime",
      render: (expectedApproachTime) => {
        let approachTime = moment(expectedApproachTime)
          .utc()
          .format("HH:mm:ss");
        return approachTime === "Invalid date" ? "NIL" : approachTime;
      },
    },
    {
      title: "HOLDING TIME",
      dataIndex: "holdingTime",
      key: "holdingTime",
      render: (holdingTime) => {
        const sign = holdingTime < 0 ? "-" : "";
        const momentObject = moment.duration(Math.abs(holdingTime));

        const hours = momentObject.hours();
        const minutes = momentObject.minutes();
        const seconds = momentObject.seconds();

        return `${sign}${hours}:${minutes}:${seconds}`;
      },
    },
    {
      title: "BASE TURN",
      dataIndex: "baseTurn",
      key: "baseTurn",
    },
    {
      title: "CROSSING ALTITUDE",
      dataIndex: "crossingAltitude",
      key: "crossingAltitude",
    },
  ];

  const getFlights = (params) => {
    setIsLoading(true);
    flights
      .getRadarFlights(params)
      .then((data) => {
        // setData(data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteOnRouteFlights = () => {
    setIsLoading(true);
    flights
      .deleteOnRouteFlights()
      .then((resolve) => {
        console.log("delete success =>", resolve);
      })
      .catch((reject) => {
        console.log("delete error =>", reject);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const setArrivingOrder = (swappedData) => {
    setIsLoading(true);
    flights
      .setArrivingOrder(swappedData)
      .then(() => {
        message.success("data update successfully");
      })
      .catch(() => {
        message.error("error while updating data");
      })
      .finally(() => {
        setIsLoading(false);
        setAircraftSeparation(defaultAircraftSeparation);
        setIsModalOpen(false);
      });
  };

  const getModifiedFlightData = useMemo(() => {
    if (!data.length) return [];
    const modifiedFlightData = data?.filter(
      (flight) => flight?.estimateArrivalTime
    );
    return modifiedFlightData.map((flight, index) => ({
      sequence: index + 1,
      ...flight,
    }));
  }, [data]);

  const getMinimumSeparation = () => {
    setIsLoading(true);
    separation
      .getMinimumSeparation()
      .then((separationData) => {
        setMinimumSeparation(separationData);
      })
      .catch((error) => {
        console.log("separation error: ", error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getMinimumSeparation();
  }, []);

  const showModal = (flightNumber, safeSeparation, isSeparationChange) => {
    setAircraftSeparation((prevState) => ({
      ...prevState,
      sourceFlightNumber: flightNumber,
      safeSeparation: safeSeparation,
      isSeparationChange: isSeparationChange,
    }));
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (isNaN(aircraftSeparation?.safeSeparation)) {
      message.error("separation can be numbers only");
      return;
    }
    if (
      aircraftSeparation?.safeSeparation !== 0 &&
      !aircraftSeparation?.safeSeparation
    ) {
      message.error("safe separation cannot empty");
      return;
    }
    if (aircraftSeparation?.safeSeparation % 22 !== 0) {
      message.error("safe separation should be divisible of 22");
      return;
    }
    const aircraftSeparationData = {
      ...aircraftSeparation,
      isSeparationChange: true,
    };
    // we are using using swapped aircrafts table to store aircraft safe separation.
    setArrivingOrder(aircraftSeparationData);
  };
  const handleCancel = () => {
    setAircraftSeparation(defaultAircraftSeparation);
    setIsModalOpen(false);
  };

  const aircraftSeparationChange = (event) => {
    const { name, value } = event.target;
    setAircraftSeparationData(name, value);
  };
  const setAircraftSeparationData = (name, value) => {
    setAircraftSeparation((prevState) => ({
      ...prevState,
      [name]: value ? Number(value) : value,
    }));
  };

  const resetAircraftSeparation = () => {
    const aircraftSeparationData = {
      ...aircraftSeparation,
      safeSeparation: null,
      isSeparationChange: false,
    };
    // we are using using swapped aircrafts table to store aircraft safe separation.
    setArrivingOrder(aircraftSeparationData);
  };
  useEffect(() => {
    // holding data while dragging row
    if (aircraftsData && !rowDragging) {
      setData(aircraftsData);
    }
  }, [aircraftsData]);
  return (
    <>
      {isLoading && <Loader />}
      <div className="main-container">
        <div className={`table-container table-open`}>
          {/* <div className="handle-container">
            <div
              className="table-handle"
              // onClick={() => setTableOpen(!tableOpen)}
            >
              <span></span>
              <span></span>
            </div>
            <Icon
              icon="ic:round-refresh"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                getFlights(params);
              }}
            />
            <Icon
              icon="material-symbols-light:delete"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                deleteOnRouteFlights();
              }}
            />
          </div> */}
          <div className={`table-content scrollbar-none overflow-auto`}>
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                items={getModifiedFlightData.map((data) => data?.flightNumber)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  columns={column}
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  showSorterTooltip={{ placement: "right" }}
                  className="aircraft-table m-1"
                  rowClassName={(record) =>
                    record.route.length ? "" : "on-hold"
                  }
                  size="small"
                  dataSource={[...getModifiedFlightData]}
                  rowKey={(data) => data?.flightNumber}
                  scroll={{
                    x: 1600,
                    // y: 240
                  }}
                  sticky={{ offsetScroll: 0 }}
                  pagination={{
                    pageSize: 30,
                    current: page,
                    total: getModifiedFlightData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    onChange: (current) => setPage(current),
                  }}
                  bordered
                />
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>

      {/* set separation modal */}
      <Modal
        title="Set Separation for Aircraft"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            className={`${
              aircraftSeparation.isSeparationChange ? "" : "d-none"
            }`}
            key="reset"
            variant="outlined"
            color="danger"
            onClick={resetAircraftSeparation}
          >
            Reset
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            variant="solid"
            color="primary"
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Input
          className="my-2"
          placeholder="Enter separation in seconds, eg 88."
          name="safeSeparation"
          type="number"
          value={aircraftSeparation.safeSeparation}
          onChange={aircraftSeparationChange}
        />
      </Modal>
    </>
  );
};

export default AircraftDataTable;
