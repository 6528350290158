import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, Form, Input, Space, Table, message, Modal } from "antd";
import { Icon } from "@iconify/react";
import separation from "../models/separation";
import Loader from "../plugin/loader/loader";

const { confirm } = Modal;

export default function AircraftSeparation() {
  const [editingKey, setEditingKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [minimumSeparation, setMinimumSeparation] = useState({
    minimumSeparation: 66,
    separationCorrection: 1,
  });
  const [form] = Form.useForm();

  const titles = {
    SH: "Super Heavy",
    UH: "Upper Heavy",
    LH: "Lower Heavy",
    UM: "Upper Medium",
    LM: "Lower Medium",
    L: "Light",
  };
  const column = [
    {
      title: "Leader",
      dataIndex: "leader",
      key: "leader",
      rowScope: "row",
      editable: false,
      render: (type) => titles[type],
    },
    {
      title: "Followers",
      children: [
        {
          title: "Super Heavy (NM)",
          dataIndex: ["followers", "SH"],
          key: "SH",
          editable: true,
        },
        {
          title: "Upper Heavy (NM)",
          dataIndex: ["followers", "UH"],
          key: "UP",
          editable: true,
        },
        {
          title: "Lower Heavy (NM)",
          dataIndex: ["followers", "LH"],
          key: "LH",
          editable: true,
        },
        {
          title: "Upper Medium (NM)",
          dataIndex: ["followers", "UM"],
          key: "UM",
          editable: true,
        },
        {
          title: "Lower Medium (NM)",
          dataIndex: ["followers", "LM"],
          key: "LM",
          editable: true,
        },
        {
          title: "Light (NM)",
          dataIndex: ["followers", "L"],
          key: "L",
          editable: true,
        },
      ],
    },
    {
      title: "Action",
      key: "Action",
      editable: false,
      render: (_, data) => {
        return data.separationId === editingKey ? (
          <Space>
            <Button
              style={{ color: "green", borderColor: "green" }}
              icon={<Icon icon="dashicons:saved" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to save these?",
                  // icon: <ExclamationCircleFilled />,
                  // content: "Some descriptions",
                  onOk() {
                    save(data.separationId);
                  },
                })
              }
            />
            <Button
              danger
              icon={<Icon icon="iconoir:cancel" />}
              onClick={() => setEditingKey("")}
            />
          </Space>
        ) : (
          <Space>
            <Button
              icon={<Icon icon="bitcoin-icons:edit-filled" />}
              onClick={() => edit(data)}
            />
            {/* <Button
              icon={<Icon icon="material-symbols-light:delete" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to delete these items?",
                  // icon: <ExclamationCircleFilled />,
                  // content: "Some descriptions",
                  onOk() {
                    handleDelete(data.separationId);
                  },
                })
              }
            /> */}
          </Space>
        );
      },
    },
  ];

  const getOnCellProps = (child, record) => ({
    record,
    dataIndex: child.dataIndex,
    title: child.title,
    editing: record.separationId === editingKey,
  });

  const mergedColumns = column.map((col) => {
    if (col.children) {
      return {
        ...col,
        children: col.children?.map((child) =>
          !child.editable
            ? child
            : { ...child, onCell: (record) => getOnCellProps(child, record) }
        ),
      };
    } else if (!col.editable) {
      return col;
    }

    return { ...col, onCell: (record) => getOnCellProps(col, record) };
  });

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      ...record.followers,
    });
    setEditingKey(record.separationId);
  };

  const save = async (id) => {
    setIsLoading(true);
    try {
      const row = await form.validateFields();
      const oldData = data.find((data) => data.separationId === id);
      const newData = { ...oldData, ...row };
      await separation.updateSeparation(newData);
      message.success("data update successfully");
    } catch (error) {
      message.error(`${error}`);
    } finally {
      setIsLoading(false);
      setEditingKey("");
      getSeparation();
    }
  };

  const handleDelete = (id) => {
    const oldData = [...data];
    const newData = oldData.filter((row) => row.separationId !== id);
    setData(newData);
  };

  const getSeparation = () => {
    setIsLoading(true);
    separation
      .getSeparation()
      .then((separationData) => {
        setData(separationData?.data);
      })
      .catch((error) => {
        console.log("separation error: ", error);
      })
      .finally(() => setIsLoading(false));
  };

  const resetSeparation = () => {
    setIsLoading(true);
    separation
      .resetSeparation()
      .then(() => {
        message.success("data reset successfully");
      })
      .catch((error) => {
        message.error("error while resetting separation data.");
        console.log("separation error: ", error);
      })
      .finally(() => {
        setIsLoading(false);
        getSeparation();
        getMinimumSeparation()
      });
  };

  const getMinimumSeparation = () => {
    setIsLoading(true);
    separation
      .getMinimumSeparation()
      .then((separationData) => {
        setMinimumSeparation(separationData);
      })
      .catch((error) => {
        console.log("separation error: ", error);
      })
      .finally(() => setIsLoading(false));
  };

  const updateMinimumSeparation = () => {
    setIsLoading(true);
    separation
      .updateMinimumSeparation(minimumSeparation)
      .then(() => {
        message.success("data updated successfully");
      })
      .catch((error) => {
        message.error("error while updating minimum separation.");
        console.log("separation error: ", error);
      })
      .finally(() => {
        setIsLoading(false);
        getMinimumSeparation();
        getSeparation();
      });
  };

  const handleMinimumSeparationChange = (event) => {
    const { name, value } = event.target;
    setMinimumSeparationChange(name, value);
  };
  const setMinimumSeparationChange = (name, value) => {
    setMinimumSeparation((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    getSeparation();
    getMinimumSeparation();
  }, []);
  return (
    <div>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-center">
        <div className="d-flex gap-3 w-50 p-2">
          <Input
            size="middle"
            value={minimumSeparation.minimumSeparation}
            name="minimumSeparation"
            onChange={handleMinimumSeparationChange}
          />
          <Input
            size="middle"
            value={minimumSeparation.separationCorrection}
            name="separationCorrection"
            onChange={handleMinimumSeparationChange}
          />
          <Button
            color="primary"
            className="float-end"
            variant="solid"
            onClick={() => updateMinimumSeparation()}
            size="middle"
          >
            Save
          </Button>
          <Button
            color="danger"
            className="float-end"
            variant="outlined"
            onClick={() => resetSeparation()}
            size="middle"
          >
            Reset
          </Button>
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          size="middle"
          bordered
          dataSource={data}
          rowKey={(data) => data.separationId}
          rowClassName={"editable-row"}
          components={{
            header: {
              cell: ({ children, ...props }) =>
                ["Followers", "Action", "Leader"].every(
                  (header) => children[1] !== header
                ) ? (
                  <th {...props} style={{ backgroundColor: "#E8E9EB" }}>
                    {children}
                  </th>
                ) : (
                  <th {...props}>{children}</th>
                ),
            },
            body: {
              cell: ({
                editing,
                dataIndex,
                title,
                record,
                index,
                children,
                ...restProps
              }) => {
                return restProps.scope ? (
                  <th {...restProps} style={{ backgroundColor: "#E8E9EB" }}>
                    {children}
                  </th>
                ) : (
                  <td {...restProps}>
                    {editing ? (
                      <Form.Item
                        name={dataIndex}
                        style={{
                          margin: 0,
                        }}
                        rules={[
                          {
                            required: false,
                            message: `Please Input ${title}!`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      children
                    )}
                  </td>
                );
              },
            },
          }}
          pagination={{
            pageSize: 10,
            total: data.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            // showSizeChanger: true,
            // showQuickJumper: true,
          }}
        />
      </Form>
    </div>
  );
}
