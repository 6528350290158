import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Viewer,
  Entity,
  GeoJsonDataSource,
  Cesium3DTileset,
  Model,
  CameraFlyTo,
  CameraLookAt,
  Sun,
  Globe,
  Clock,
} from "resium";
import {
  Cartesian3,
  HeadingPitchRoll,
  Math as CesiumMath,
  Ion,
  createWorldTerrainAsync,
  IonResource,
  Transforms,
  SceneMode,
  Ellipsoid,
  JulianDate,
  ClockRange,
  ClockStep,
  SampledPositionProperty,
  Quaternion,
  TimeIntervalCollection,
  TimeInterval,
  VelocityOrientationProperty,
  PathGraphics,
  Color,
  PolylineGlowMaterialProperty,
  Cartesian2,
  HorizontalOrigin,
  VerticalOrigin,
} from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import aircraftModel from "../../assets/Cesium_Air.glb";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../plugin/AppContext";
import flightData from "./flightData";

const cesiumToken = process.env.REACT_APP_CESIUM_TOKEN;
Ion.defaultAccessToken = cesiumToken;

// Set Cesium base URL for assets
window.CESIUM_BASE_URL = "/Cesium";

const defaultAicraftLocation = {
  longitude: 55.728425,
  latitude: 25.0732972,
  altitude: 12000,
  heading: 180,
};

function View3D() {
  const { id } = useParams();
  const [prevUpdateTime, setPrevUpdateTime] = useState(new Date().getTime()); // in milli seconds

  const clockRef = useRef(null);
  const positionProperty = useMemo(() => new SampledPositionProperty(), []);
  const { aircraftsData } = useAppContext();
  const [altitudeGap, setAltitudeGap] = useState(null);
  const terrainProvider = createWorldTerrainAsync();

  // const timeStepsInSeconds = 30;
  // const totalSeconds = timeStepsInSeconds * (flightData.length - 1);

  const startTime = useMemo(() => JulianDate.now(), []);
  const stopTime = useMemo(
    () => JulianDate.addHours(startTime, 12, new JulianDate()),
    [startTime]
  );

  positionProperty.setInterpolationOptions({
    interpolationDegree: 5, // Higher degree results in smoother interpolation
    interpolationAlgorithm: CesiumMath.HermitePolynomialApproximation, // Hermite for smooth transitions
  });

  useEffect(() => {
    const aircraftData = aircraftsData?.find(
      (aircraft) => aircraft?.identification?.id === id
    );
    if (aircraftData) {
      setAltitudeGap(aircraftData.altitudeGap);
      const timeNow = new Date().getTime();
      let timeDifference = timeNow - prevUpdateTime;
      timeDifference = Math.round(timeDifference / 1000);

      const { lat, lng, alt } = aircraftData?.trail[0];

      const time = JulianDate.addSeconds(
        JulianDate.now(),
        timeDifference || 6,
        new JulianDate()
      );
      const position = Cartesian3.fromDegrees(lng, lat, alt);
      positionProperty.addSample(time, position);
      setPrevUpdateTime(timeNow);
    }
  }, [aircraftsData, id]);

  const airplaneEntity = useMemo(() => {
    return (
      <Entity
        availability={
          new TimeIntervalCollection([
            new TimeInterval({ start: startTime, stop: stopTime }),
          ])
        }
        position={positionProperty}
        model={{
          uri: aircraftModel,
          minimumPixelSize: 128,
          maximumScale: 256,
          runAnimations: false,
        }}
        orientation={new VelocityOrientationProperty(positionProperty)}
        label={{
          text: String(altitudeGap ?? ""),
          font: "20px sans-serif",
          fillColor: Color.LIGHTGREEN,
          outlineColor: Color.BLACK,
          outlineWidth: 3,
          pixelOffset: new Cartesian2(0, -100),
          horizontalOrigin: HorizontalOrigin.CENTER,
          verticalOrigin: VerticalOrigin.TOP,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        }}
        // path={
        //   new PathGraphics({
        //     width: 3,
        //     material: Color.RED,
        //   })
        // }
        tracked
      />
    );
  }, [id, positionProperty, altitudeGap]);

  const clockEntity = useMemo(() => {
    return (
      <Clock
        ref={clockRef}
        startTime={startTime.clone()}
        currentTime={startTime.clone()}
        stopTime={stopTime.clone()}
        clockRange={ClockRange.LOOP_STOP}
        multiplier={1} // Adjust this to control animation speed
        shouldAnimate
      />
    );
  }, []);

  const aircraftRoute = useMemo(() => {
    const aircraftData = aircraftsData?.find(
      (aircraft) => aircraft?.identification?.id === id
    );
    if (aircraftData) {
      const { alt } = aircraftData?.trail[0];
      let routePositions = aircraftData?.route?.map(({ lat, lng }) =>
        Cartesian3.fromDegrees(lng, lat, alt)
      );

      const currentJulianTime = JulianDate.addSeconds(
        JulianDate.now(),
        -6,
        new JulianDate()
      );
      const currentPosition = positionProperty.getValue(currentJulianTime);

      if (currentPosition) {
        routePositions = [currentPosition, ...routePositions];
      }
      return (
        <Entity
          polyline={{
            positions: routePositions,
            width: 4,
            material: Color.LIGHTGREEN,
            clampToGround: false,
          }}
        />
      );
    }
    return null;
  }, [aircraftsData, id]);

  return (
    <Viewer
      full
      terrainProvider={terrainProvider}
      navigationHelpButton={false}
      navigationInstructionsInitiallyVisible={false}
      homeButton={false}
      sceneModePicker={false}
      timeline={false}
      geocoder={false}
      fullscreenButton={false}
      baseLayerPicker={false}
      shadows={false}
      animation={false}
      shouldAnimate={true} // Smooth animation control
      targetFrameRate={30} // Limit FPS to reduce CPU/GPU usage
      resolutionScale={0.75}
      useBrowserRecommendedResolution={true}
      performanceWatchdog={true}
      // vrButton={false}
      // baseLayerPicker={false}
      // infoBox={false}
      // scene3DOnly
      // selectionIndicator={false}
    >
      {clockEntity}
      {airplaneEntity}
      {aircraftRoute}
    </Viewer>
  );
}

export default View3D;
